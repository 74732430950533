import api from "../Axios";

// 获取营销活动分组统计数据
export const apiPlanSummary = () => api.get(`/campaigns/stat`);

// 获取营销活动列表
export const apiGetPlanList = (params) => api.get(`/campaigns`, { params });

// 获取营销活动详情
export const apiGetPlanDetail = (id) => api.get(`/campaign/${id}`);

// 删除营销活动详情
export const apiDeletePlan = (id) => api.delete(`/campaign/${id}`);

// 暂停营销活动
export const apiPausePlan = (id) => api.put(`/campaign/pause/${id}`);

// 创建营销活动
export const apiAddPlan = (params) => api.post(`/campaign`, params);

// 编辑营销活动
export const apiUpdatePlan = (id, params) => api.put(`/campaign/${id}`, params);

export const apiGetChartList = () => api.get(`/campaign/charts`);

export const apiGetChartDetail = (_id, id) =>
  api.get(`/campaign/chart/${_id}/${id}`);

// 获取模板列表
export const apiGetTemplateList = (params) =>
  api.get("/campaign-templates", { params });

// 获取模板详情
export const apiGetTemplateDetail = (id) =>
  api.get(`/campaign-templates/${id}`);

// 从活动创建模板
export const addCampaign = (params) => api.post(`/campaign-templates`, params);

// 修改模板
export const editCampaign = (id, params) =>
  api.put(`/campaign-templates/${id}`, params);

// 获取运营计划员工执行 chart 列表
export const apiGetComGroupReportChartsList = () =>
  api.get(`/campaign/comGroupReportCharts`);

// 获取运营计划员工执行 chart 详情
export const apiGetReportChartDetail = (_id, id) =>
  api.get(`/campaign/com-group-report-chart/${_id}/${id}`);

// 获取运营计划员工执行明细
export const apiGetReportList = (id, params) =>
  api.get(`/campaign/com-group-reports/${id}`, { params });

// 获取运营计划客户触达明细
export const apiGetTriggerList = (id, params) =>
  api.get(`/campaign/cus-trigger-reports/${id}`, { params });

// 获取发送人列表
export const apiGetSenderList = (data) =>
  api.post("/message-channel/get-sender-list", data)