<template>
  <cond-item-wrap
    title="ID上传"
    subTitle="通过批量上传ID来细分"
    :showPlaceholder="false"
    :isEmpty="isEmpty"
  >
    <cond-item
      v-for="(item, index) in condCustList"
      :ref="item._id"
      :key="item._id"
      :detail="item"
      :index="count + index"
      :fields="idUploadFields"
      @remove="handleRemoveCondCust"
      :disabled="disabled"
    />
    <div v-if="nowIdFile" class="cond-item-customer">
      <div class="item">
        <div class="label">ID类型</div>
        <div style="line-height: 36px">
          <el-radio-group v-model="nowIdFile.idType" @change="onIDType">
            <el-radio :label="key" v-for="key in idTypeList" :key="key"
              >{{ key }}
            </el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="item">
        <div class="label">上传文件</div>
        <upload
          @file="changeUpload"
          @remove="removeUpload"
          :isExecute="true"
          :url="nowIdFile.FileURL"
          :selIdType="nowIdFile.idType"
          :type="AttachmentType"
          :uploadText="uploadText"
          :ruleStr="ruleStr"
          :before="uploadBefore"
          :idHelpFile="true"
          ref="uploadRef"
          accept=".csv, .CSV"
        />
      </div>
    </div>
    <div>
      <template v-if="!nowIdFile">
        <div :style="isEmpty ? 'margin-left: 16px' : ''">
          <el-button type="text" :disabled="disabled" @click="handleAddCondCust"
            >+ 添加条件
          </el-button>
          <el-button
            type="text"
            :disabled="disabled"
            @click="handleAddCondCustType"
            >+ 上传一批新的ID
          </el-button>
        </div>
      </template>
      <!-- <template v-else>
        <el-button
          :disabled="disabled"
          @click="handleAddCondCustSubmit"
          type="primary"
          >提交
        </el-button>
        <el-button :disabled="disabled" @click="handleAddCondCustCancel"
          >取消
        </el-button>
      </template> -->
    </div>
  </cond-item-wrap>
</template>

<script>
import { mapGetters } from "vuex";
import upload from "@/components/upload.vue";
import util from "@/util";

import condItemWrap from "./cond-item-wrap";
import condItem from "./cond-item";

export default {
  name: "id-filter",
  components: {
    upload,
    condItemWrap,
    condItem,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      AttachmentType: "csvFile",
      uploadText: "文件",
      ruleStr: "仅支持CSV文件上传", // 上传格式辅助提示
      url: "", // 已经上传的文件地址
      condCustList: [],
      nowIdFile: null,
    };
  },
  computed: {
    ...mapGetters({
      idUploadFields: "common/idUploadFields",
    }),
    idTypeList() {
      const name = "IdType";
      const field = this.idUploadFields.find((item) => item.APIName === name);
      const list = [];
      if (field) {
        (field.PicklistOptions || []).forEach((val) => {
          list.push(val);
        });
      }
      return list;
    },
    isEmpty() {
      let bool = true;
      if (this.nowIdFile || this.condCustList.length) {
        bool = false;
      }
      return bool;
    },
  },
  methods: {
    uploadBefore() {
      let bool = true;
      if (this.nowIdFile && !this.nowIdFile.IdType) {
        bool = false;
        this.$message.error("请先选择ID类型");
      }
      return bool;
    },
    onIDType(val) {
      if (this.nowIdFile) {
        this.nowIdFile.IdType = val;
      }
    },
    changeUpload(file) {
      if (this.nowIdFile) {
        this.nowIdFile.FileURL = file.url;
        this.nowIdFile.FileName = file.name;
        this.nowIdFile.FileId = file.fileId;
      }
      this.handleAddCondCustSubmit()
    },
    removeUpload() {
      this.nowIdFile.FileURL = "";
      this.nowIdFile.FileName = "";
      this.nowIdFile.FileId = "";
    },
    validate() {
      let valid = true; // 是否验证通过
      this.condCustList.forEach((item) => {
        let row = this.$refs[item._id] || [];
        row = row[0];
        if (row) {
          valid = row.validate() === false ? false : valid;
        }
      });
      return valid;
    },
    setFormData(data) {
      this.condCustList = data || [];
    },
    getFormData() {
      return this.condCustList;
    },
    getIdFiles() {
      const IdFiles = [];
      this.condCustList.forEach((item) => {
        if (item.IdFile) {
          IdFiles.push(item.IdFile);
        }
      });
      return IdFiles;
    },
    handleRemoveCondCust(detail) {
      const index = this.condCustList.indexOf(detail);
      this.condCustList.splice(index, 1);
    },

    handleAddCondCust() {
      this.condCustList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
      this.emit();
    },
    handleAddCondCustType() {
      const id = util.randomString(6);
      this.nowIdFile = {
        IdType: "",
        FileName: "",
        FileId: "",
        FileURL: "",
        id: id,
      };
    },
    // 默认选择文件名称 FileName
    handleAddCondCustSubmit() {
      if (
        !this.nowIdFile ||
        (this.nowIdFile && !this.nowIdFile.FileName) ||
        !this.nowIdFile.IdType ||
        !this.nowIdFile.FileURL ||
        !this.nowIdFile.FileId
      ) {
        this.$message.error("请设置条件");
        return;
      }
      this.condCustList.push({
        _id: this.nowIdFile.id,
        FieldAPIName: "FileName",
        Operator: "equals",
        Value: this.nowIdFile.FileName,
        IdFile: {
          IdType: this.nowIdFile.IdType,
          FileName: this.nowIdFile.FileName,
          FileId: this.nowIdFile.FileId,
          FileURL: this.nowIdFile.FileURL,
        },
      });
      this.handleAddCondCustCancel();
      this.emit();
    },
    handleAddCondCustCancel() {
      this.nowIdFile = null;
    },
    emit() {
      this.$emit("change", JSON.parse(JSON.stringify(this.condCustList)));
    },
  },
};
</script>

<style lang="less" scoped>
.item {
  display: flex;
  margin-bottom: 25px;

  .label {
    width: 100px;
    font-size: 12px;
    line-height: 36px;
    &:before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
