import api from "../Axios";

export const apiGetGroups = (params) => api.get("/customer-groups", { params });

export const apiGetGroupDetail = (id) => api.get(`/customer-group/${id}`);

export const apiAddGroup = (params) => api.post("/customer-group", params);

export const apiUpdateGroup = (id, params) =>
  api.post(`/customer-group/${id}`, params);

export const apiDeleteGroupDetail = (id) => api.delete(`/customer-group/${id}`);

// 客户列表
export const apiGetCustomers = (id, params) =>
  api.get(`/customer-group/${id}/customers`, { params });

export const apiGetCustomerDetail = (id) => api.get(`/customer/${id}`);

export const apiGetCarDetail = (id) => api.get(`/car/${id}`);

export const apiGetChartList = (id, params) =>
  api.get(`/customer-group/charts`, { params });

export const apiGetChartDetail = (_id, id, params) =>
  api.get(`/customer-group/chart/${_id}/${id}`, { params });

export const apiGetCustomerBeha = (id, params) =>
  api.get(`/customer/${id}/behaviors`, { params });

export const apiGetCarBeha = (id, params) =>
  api.get(`/car/${id}/behaviors`, { params });

// 车辆列表
export const apiGetCars = (id, params) =>
  api.get(`/customer-group/${id}/cars`, { params });

// 客户充值积分列表
export const apiGetPointsAndRecharges = (id, params) =>
  api.get(`/customer/${id}/pointsAndRecharges`, { params });

// 客户卡券列表
export const apiGetCardsAndVouchers = (id, params) =>
  api.get(`/customer/${id}/cardsAndVouchers`, { params });

// 车辆卡券列表
export const apiGetReadDealerCardsAndVouchers = (id, params) =>
  api.get(`/car/${id}/readDealerCardsAndVouchers`, { params });

// 车辆会员信息列表
export const apiGetReadDealerPointsAndRecharges = (id, params) =>
  api.get(`/car/${id}/readDealerPointsAndRecharges`, { params });

// 导出
export const apiExport = (id, params) =>
  api.post(`/customer-group/export/${id}`, params);
// 客户chart列表
export const apiGetCustomerChartList = () => api.get(`/customer/chart/list`);

// 客户chart详情
export const apiGetCustomerChartDetail = (_id, id) =>
  api.get(`/customer/chart/${_id}/${id}`);

// 客户car列表
export const apiGetCarChartList = () => api.get(`/car/chart/list`);

// 客户car详情
export const apiGetCarChartDetail = (_id, id) =>
  api.get(`/car/chart/${_id}/${id}`);

// 某个客户下的车列表
export const apiGetCustomerCars = (id) => api.get(`/customer/${id}/cars`);

// 某个车下的客户列表
export const apiGetCarsCustomer = (id) => api.get(`/car/${id}/customers`);

// 客户分组导出任务列表
export const apiCustomerGroupExported = (params) =>
  api.get("customer-group/exported", { params });

// 客户连接渠道列表
export const apiGetChannels = (id, params) =>
  api.get(`/customer/${id}/channels`, { params });

  // 刷新分组人数
export const apiRefreshGroupNumber = (id) => 
  api.get(`/customer-group/refresh-number/${id}`)
  