<template>
  <el-row :gutter="12">
    <el-col :span="11">
      <el-input
        type="number"
        placeholder="请输入内容"
        v-model="range[0]"
        @change="handleChange(0)"
        :disabled="disabled"
      ></el-input>
    </el-col>
    <el-col :span="2" style="line-height: 36px; text-align: center">~</el-col>
    <el-col :span="11">
      <el-input
        type="number"
        placeholder="请输入内容"
        v-model="range[1]"
        @change="handleChange(1)"
        :disabled="disabled"
      ></el-input>
    </el-col>
    <div class="err-msg" v-if="errorMsg">{{ errorMsg }}</div>
  </el-row>
</template>

<script>
export default {
  props: {
    range: {
      type: Array,
      default: () => [0, 1],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      errorMsg: "",
    };
  },
  methods: {
    handleChange() {
      if (this.range[0] === "" || this.range[1] === "") {
        this.errorMsg = "请输入完整的区间值";
        return;
      }
      if (+this.range[0] >= +this.range[1]) {
        this.errorMsg = "右侧必须大于左侧";
      } else {
        this.errorMsg = "";
      }
    },
    getErrorMsg() {
      return this.errorMsg;
    },
  },
};
</script>

<style lang="less" scoped>
.err-msg {
  position: absolute;
  padding: 4px 6px 0 6px;
  color: #f56c6c;
  font-size: 12px;
  line-height: 1;
  top: 100%;
  left: 0;
}
</style>
