<template>
  <cond-card :title="title">
    <!-- <div style="margin-bottom: 16px">
      <ModelFilter ref="modelFilter" :disabled="disabled" />
    </div> -->
    <div class="customer-filter-flod-area" :style="flod? 'max-height: '+ maxHeight +'px;overflow: hidden;margin-bottom: 32px;': ''">
      <cond-item-wrap
        title="连接渠道"
        subTitle="按照客户和集团/门店线上交互渠道信息细分"
        placeholder="所有客户"
        :isEmpty="condCliquePointsAndRechargesList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condCliquePointsAndRechargesList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="modelFilterList.length + index"
          :fields="channelCustomerRelation"
          @remove="handleRemoveCliquePointsAndRecharges"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled"
            type="text"
            @click="handleAddCliquePointsAndRecharges"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        title="客户"
        subTitle="此分群包含哪些客户？"
        placeholder="所有用户"
        :isEmpty="condCustList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condCustList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="modelFilterList.length + condCliquePointsAndRechargesList.length + index"
          :fields="customerFields"
          @remove="handleRemoveCondCust"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button type="text" :disabled="disabled" @click="handleAddCondCust"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        title="车辆"
        subTitle="此分群包含哪些车辆？"
        placeholder="所有车辆"
        :isEmpty="condCarList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condCarList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="modelFilterList.length + condCliquePointsAndRechargesList.length + condCustList.length + index"
          :fields="carFields"
          @remove="handleRemoveCondCar"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button type="text" :disabled="disabled" @click="handleAddCondCar"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>

      <cond-item-wrap
        title="集团会员卡券"
        subTitle="按照集团分发的积分或卡券信息细分"
        placeholder="所有客户"
        :isEmpty="condCliqueCardsAndVouchersList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condCliqueCardsAndVouchersList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="
            modelFilterList.length +
            condCliquePointsAndRechargesList.length +
            condCustList.length +
            condCarList.length +
            index
          "
          :fields="cliqueCardsAndVouchers"
          @remove="handleRemoveCliqueCardsAndVouchers"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled"
            type="text"
            @click="handleAddCliqueCardsAndVouchers"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        title="企业积分"
        subTitle="按照企业效积分或者充值细分"
        placeholder="所有车辆"
        :isEmpty="condDealerPointsAndRechargesList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condDealerPointsAndRechargesList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="
            modelFilterList.length +
            condCustList.length +
            condCarList.length +
            condCliquePointsAndRechargesList.length +
            condCliqueCardsAndVouchersList.length +
            index
          "
          :fields="dealerPointsAndRecharges"
          @remove="handleRemoveDealerPointsAndRecharges"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled"
            type="text"
            @click="handleAddDealerPointsAndRecharges"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        title="企业卡券"
        subTitle="按照各集团分发的卡券信息细分"
        placeholder="所有车辆"
        :isEmpty="condDealerCardsAndVouchersList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condDealerCardsAndVouchersList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="
            modelFilterList.length +
            condCustList.length +
            condCarList.length +
            condCliquePointsAndRechargesList.length +
            condCliqueCardsAndVouchersList.length +
            condDealerPointsAndRechargesList.length +
            index
          "
          :fields="dealerCardsAndVouchers"
          @remove="handleRemoveDealerCardsAndVouchers"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button
            :disabled="disabled"
            type="text"
            @click="handleAddDealerCardsAndVouchers"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <cond-item-wrap
        title="关系"
        subTitle="按照客户和车辆的关系细分"
        placeholder="所有关系"
        :isEmpty="condRelaList.length === 0"
      >
        <cond-item
          v-for="(item, index) in condRelaList"
          :ref="item._id"
          :key="item._id"
          :detail="item"
          :index="
            modelFilterList.length +
            condCustList.length +
            condCarList.length +
            condCliquePointsAndRechargesList.length +
            condCliqueCardsAndVouchersList.length +
            condDealerPointsAndRechargesList.length +
            condDealerCardsAndVouchersList.length +
            index
          "
          :fields="relationFields"
          @remove="handleRemoveCondRela"
          :disabled="disabled"
        ></cond-item>
        <div>
          <el-button type="text" :disabled="disabled" @click="handleAddCondRela"
            >+ 添加条件
          </el-button>
        </div>
      </cond-item-wrap>
      <IdFilter
        v-if="title!='客户分流筛选条件'"
        :disabled="disabled"
        ref="idFilter"
        :count="
          modelFilterList.length +
          condCustList.length +
          condCarList.length +
          condCliquePointsAndRechargesList.length +
          condRelaList.length
        "
        @change="handleIdFilterList"
      />
      <div v-if="flod" class="overflow-cover">
        <el-button type="text" v-if="flod" @click="flod = false"
          ><i class="el-icon-arrow-down"></i> 更多分群条件</el-button
        >
      </div>
    </div>
    <div class="cond-combination" v-if="title == '分群条件' || !flod">
      <div class="title">筛选条件的逻辑和要求</div>
      <div class="content">
        <el-form-item
          label="筛选条件要求"
          label-width="84px"
          prop="FilterConditionType"
        >
          <el-select
            v-model="FilterConditionType"
            :disabled="disabled"
            style="width: 200px"
          >
            <el-option label="同时满足所有条件（AND）" value="AND"></el-option>
            <el-option label="满足其中任一条件（OR）" value="OR"></el-option>
            <el-option label="自定义逻辑" value="Custom"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="筛选条件逻辑"
          label-width="84px"
          prop="FilterConditionLogic"
        >
          <el-input
            v-model="FilterConditionLogic"
            placeholder="请输入筛选条件逻辑"
            :disabled="FilterConditionType !== 'Custom' || disabled"
          ></el-input>
        </el-form-item>
      </div>
    </div>
  </cond-card>
</template>

<script>
import { mapGetters } from "vuex";
import util from "@/util";
import condCard from "./cond-card";
import condItemWrap from "./cond-item-wrap";
import condItem from "./cond-item";
// import ModelFilter from "./model-filter.vue";
import IdFilter from "./id-filter.vue";

export default {
  components: {
    condCard,
    condItemWrap,
    condItem,
    //ModelFilter,
    IdFilter,
  },
  props: {
    title: {
      type: String,
      default: "分群条件",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    exist: {
      type: Boolean,
      default: false,
    },
    maxHeight: {
      type: Number,
      required: true
    },
    setDefaultCusFilter: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      FilterConditionType: "AND",
      FilterConditionLogic: "",
      FilterGroups: [],
      condCustList: [],
      condCarList: [],
      condCliquePointsAndRechargesList: [],
      condCliqueCardsAndVouchersList: [],
      condDealerPointsAndRechargesList: [],
      condDealerCardsAndVouchersList: [],
      condRelaList: [],
      idFilterList: [],
      flod: true,
    };
  },
  watch: {
    FilterConditionType: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    FilterConditionLogic: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    FilterGroups: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condCustList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condCarList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condCliquePointsAndRechargesList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condCliqueCardsAndVouchersList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condDealerPointsAndRechargesList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condDealerCardsAndVouchersList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    condRelaList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    idFilterList: {
      handler(val) {
        this.$emit('conditions-change', this.getResult())
      },
      deep: true
    },
    filterTxt(newVal) {
      this.FilterConditionLogic = newVal;
    },
  },
  computed: {
    ...mapGetters({
      customerFields: "common/customerFields",
      carFields: "common/carFields",
      carBehaFields: "common/carBehaFields",
      relationFields: "common/relationFields",
      cliquePointsAndRecharges: "common/cliquePointsAndRecharges",
      cliqueCardsAndVouchers: "common/cliqueCardsAndVouchers",
      dealerPointsAndRecharges: "common/dealerPointsAndRecharges",
      dealerCardsAndVouchers: "common/dealerCardsAndVouchers",
      channelCustomerRelation: "common/channelCustomerRelation",
    }),
    filterTxt() {
      const {
        condCustList,
        condCarList,
        condCliquePointsAndRechargesList,
        condCliqueCardsAndVouchersList,
        condDealerPointsAndRechargesList,
        condDealerCardsAndVouchersList,
        condRelaList,
        FilterConditionType,
        FilterConditionLogic,
        idFilterList,
      } = this;
      let modelFilterList = [];
      // if (this.$refs.modelFilter) {
      //   modelFilterList = this.$refs.modelFilter.getFormData();
      // }
      if (FilterConditionType === "Custom") {
        return FilterConditionLogic;
      }
      const count =
        modelFilterList.length +
        condCustList.length +
        condCarList.length +
        condCliquePointsAndRechargesList.length +
        condCliqueCardsAndVouchersList.length +
        condDealerPointsAndRechargesList.length +
        condDealerCardsAndVouchersList.length +
        condRelaList.length +
        idFilterList.length;

      let result = "";
      for (let i = 1; i <= count; i++) {
        result += i;
        result += i === count ? "" : ` ${FilterConditionType} `;
      }
      return result;
    },
    modelFilterList() {
      let modelFilterList = [];
      // if (this.$refs.modelFilter) {
      //   modelFilterList = this.$refs.modelFilter.getFormData();
      // }
      return modelFilterList;
    },
    nums() {
      const {
        condCustList,
        condCarList,
        condCliquePointsAndRechargesList,
        condCliqueCardsAndVouchersList,
        condDealerPointsAndRechargesList,
        condDealerCardsAndVouchersList,
        condRelaList,
        idFilterList,
      } = this;
      let modelFilterList = [];
      if (this.$refs.modelFilter) {
        modelFilterList = this.$refs.modelFilter.getFormData();
      }
      const count =
        modelFilterList.length +
        condCustList.length +
        condCarList.length +
        condCliquePointsAndRechargesList.length +
        condCliqueCardsAndVouchersList.length +
        condDealerPointsAndRechargesList.length +
        condDealerCardsAndVouchersList.length +
        condRelaList.length +
        idFilterList.length;

      const result = [];
      for (let i = 1; i <= count; i++) {
        result.push(i);
      }

      return result;
    },
  },
  methods: {
    handleIdFilterList(list) {
      this.idFilterList = list;
    },
    handleAddCondCust() {
      this.condCustList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCondCust(detail) {
      const index = this.condCustList.indexOf(detail);
      this.condCustList.splice(index, 1);
    },
    handleAddCondCar() {
      this.condCarList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCondCar(detail) {
      const index = this.condCarList.indexOf(detail);
      this.condCarList.splice(index, 1);
    },
    // 添加 移除集团积分
    handleAddCliquePointsAndRecharges() {
      this.condCliquePointsAndRechargesList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCliquePointsAndRecharges(detail) {
      const index = this.condCliquePointsAndRechargesList.indexOf(detail);
      this.condCliquePointsAndRechargesList.splice(index, 1);
    },
    // 添加 移除集团卡券
    handleAddCliqueCardsAndVouchers() {
      this.condCliqueCardsAndVouchersList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCliqueCardsAndVouchers(detail) {
      const index = this.condCliqueCardsAndVouchersList.indexOf(detail);
      this.condCliqueCardsAndVouchersList.splice(index, 1);
    },
    // 添加 移除企业积分
    handleAddDealerPointsAndRecharges() {
      this.condDealerPointsAndRechargesList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveDealerPointsAndRecharges(detail) {
      const index = this.condDealerPointsAndRechargesList.indexOf(detail);
      this.condDealerPointsAndRechargesList.splice(index, 1);
    },
    // 添加 移除企业卡券
    handleAddDealerCardsAndVouchers() {
      this.condDealerCardsAndVouchersList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveDealerCardsAndVouchers(detail) {
      const index = this.condDealerCardsAndVouchersList.indexOf(detail);
      this.condDealerCardsAndVouchersList.splice(index, 1);
    },
    handleAddCondRela() {
      this.condRelaList.push({
        _id: util.randomString(6),
        FieldAPIName: "",
        Operator: "",
        Value: "",
      });
    },
    handleRemoveCondRela(detail) {
      const index = this.condRelaList.indexOf(detail);
      this.condRelaList.splice(index, 1);
    },
    resetForm() {
      this.FilterConditionType = "AND";
      this.FilterConditionLogic = "";
      this.FilterGroups = [];
      this.condCustList = [];
      this.condCarList = [];
      this.condCliquePointsAndRechargesList = [];
      this.condCliqueCardsAndVouchersList = [];
      this.condDealerPointsAndRechargesList = [];
      this.condDealerCardsAndVouchersList = [];
      this.condRelaList = [];
    },
    initForm(data) {
      Object.assign(this.$data, data);
      // this.$refs.modelFilter.setFormData(data.modelFilterList || []);
      if (this.title!='客户分流筛选条件') {
        this.$refs.idFilter.setFormData(data.idFilterList || []);
      }
    },
    validate() {
      const validList = [
        ...this.condCustList,
        ...this.condCarList,
        ...this.condCliquePointsAndRechargesList,
        ...this.condCliqueCardsAndVouchersList,
        ...this.condDealerPointsAndRechargesList,
        ...this.condDealerCardsAndVouchersList,
        ...this.condRelaList,
      ];

      let valid = true; // 是否验证通过
      validList.forEach((item) => {
        let row = this.$refs[item._id] || [];
        row = row[0];
        if (row) {
          valid = row.validate() === false ? false : valid;
        }
      });

      // if (this.$refs.modelFilter) {
      //   valid = this.$refs.modelFilter.validate();
      // }
      if (this.$refs.idFilter) {
        valid = this.$refs.idFilter.validate();
      }

      // if (this.FilterConditionType === "Custom") {
      //   valid = util.filterConditionLogicRule(
      //     this.FilterConditionLogic,
      //     this.nums
      //   );
      //   console.log(this.filterTxt);
      // }

      return valid;
    },
    getResult() {
      if (this.validate() === false) {
        return false;
      }

      const { FilterConditionType, FilterConditionLogic } = this;
      const param = {
        FilterConditionType,
        FilterConditionLogic,
        FilterGroups: [],
        IdFiles: [],
      };

      let modelFilterList = [];
      let idFilterList = [];

      // if (this.$refs.modelFilter) {
      //   modelFilterList = this.$refs.modelFilter.getFormData();
      // }
      if (this.$refs.idFilter) {
        idFilterList = this.$refs.idFilter.getFormData();
        param.IdFiles = this.$refs.idFilter.getIdFiles();
      }

      // 下面说明下：得按照界面的顺序推送到队列里，后端需要根据这个顺序来解析

      if (modelFilterList.length > 0) {
        modelFilterList.forEach((item) => {
          param.FilterGroups.push({
            FilterGroup: "模型",
            ObjectAPIName: item.nowobject,
            FilterItems: [item],
          });
        });
      }
      this.condCliquePointsAndRechargesList.length > 0 &&
      param.FilterGroups.push({
        FilterGroup: "连接渠道",
        ObjectAPIName: "ChannelCustomerRelation",
        FilterItems: this.condCliquePointsAndRechargesList,
      });
      this.condCustList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "客户",
          ObjectAPIName: "Customer",
          FilterItems: this.condCustList,
        });
      this.condCarList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "车辆",
          ObjectAPIName: "Car",
          FilterItems: this.condCarList,
        });
      this.condCliqueCardsAndVouchersList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "集团会员卡券",
          ObjectAPIName: "CliqueCardsAndVouchers",
          FilterItems: this.condCliqueCardsAndVouchersList,
        });
      this.condDealerPointsAndRechargesList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "企业积分",
          ObjectAPIName: "DealerPointsAndRecharges",
          FilterItems: this.condDealerPointsAndRechargesList,
        });
      this.condDealerCardsAndVouchersList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "企业卡券",
          ObjectAPIName: "DealerCardsAndVouchers",
          FilterItems: this.condDealerCardsAndVouchersList,
        });

      this.condRelaList.length > 0 &&
        param.FilterGroups.push({
          FilterGroup: "关系",
          ObjectAPIName: "CustomerCarRelation",
          FilterItems: this.condRelaList,
        });

      if (idFilterList.length > 0) {
        idFilterList.forEach((item) => {
          // if (item.IdFile) {
          //   delete item.IdFile;
          // }
          param.FilterGroups.push({
            FilterGroup: "ID上传",
            ObjectAPIName: "IDUpload_CID",
            FilterItems: [item],
          });
        });
      }

      return param;
    },
  },
  mounted() {
    if (this.setDefaultCusFilter) {
      if (this.channelCustomerRelation.find(item => item.APIName === 'IsReject')) {
        this.condCliquePointsAndRechargesList.push({
          _id: util.randomString(6),
          FieldAPIName: "IsReject",
          Operator: "excludes",
          Value: ["是"],
        });
      }
    }
    this.$emit('conditions-change', this.getResult())
  },
};
</script>

<style lang="less" scoped>
.cond-combination {
  .title {
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
  }

  .content {
    padding: 20px;
    padding-bottom: 6px;
    background: #f6f7f8;
    border-radius: 6px;
  }
}
.customer-filter-flod-area {
  position: relative;
  height: auto;
  .overflow-cover {
    position: absolute;
    width: 100%;
    height: 64px;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(
      to top,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.25)
    );
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
</style>
