<template>
  <div class="cond-item-customer">
    <el-row :gutter="20">
      <!-- 序号 -->
      <el-col :span="1" class="index-wrap">
        <div class="index">{{ index + 1 }}</div>
      </el-col>
      <!-- 自定义参数 -->
      <div v-if="fieldsShow === 'APIParameter'">
        <el-col :span="11">
          <el-input
            v-if="detail.type === 'parameter'"
            v-model="detail.parameterName"
            placeholder="请输入自定义参数名称"
          ></el-input>
          <el-input
            v-if="detail.type === 'time'"
            v-model="detail.parameterName"
            placeholder="请输入时间戳参数名称"
          ></el-input>
          <el-input
            v-if="detail.type === 'sign'"
            v-model="detail.parameterName"
            placeholder="请输入签名算法参数名称"
          ></el-input>
        </el-col>
        <el-col :span="10">
          <el-input
            v-if="detail.type === 'parameter'"
            v-model="detail.parameterKey"
            placeholder="请输入参数对应的值"
          ></el-input>
          <el-select
            v-if="detail.type === 'time'"
            v-model="detail.parameterKey"
            placeholder="请选择时间戳格式类型"
            style="width: 100%"
            filterable
          >
            <el-option label="秒(s)" value="s"></el-option>
            <el-option label="毫秒(ms)" value="ms"></el-option>
          </el-select>
          <el-select
            v-if="detail.type === 'sign'"
            v-model="detail.parameterKey"
            placeholder="请选择签名算法"
            style="width: 100%"
            filterable
          >
            <el-option label="MD5" value="MD5"></el-option>
            <el-option label="RSA" value="RSA"></el-option>
          </el-select>
        </el-col>
      </div>
      <!-- 自定义参数返回 -->
      <div v-else-if="fieldsShow === 'RESAPIParameter'">
        <el-col :span="7">
          <el-input
            v-model="detail.resName"
            placeholder="请输入自定义参数名称"
            disabled
          ></el-input>
        </el-col>
        <el-col :span="7">
          <el-input
            v-model="detail.resKey"
            placeholder="请输入自定义接口对应参数名称"
          ></el-input>
        </el-col>
        <el-col :span="7">
          <el-input
            v-if="detail.type === 'ttl'"
            v-model="detail.resDefault"
            min="0"
            type="number"
            placeholder="输入默认值 (单位秒)"
          ></el-input>
        </el-col>
      </div>
      <!-- 原 -->
      <div v-else>
        <!-- 第一列：指标 -->
        <el-col :span="8">
          <el-select
            v-model="detail.FieldAPIName"
            @change="handleFieldChange"
            style="width: 100%"
            :disabled="disabled"
            filterable
            placeholder="请选择或搜索"
          >
            <el-option
              v-for="item in fields"
              :key="item.APIName"
              :label="item.Name"
              :value="item.APIName"
            ></el-option>
          </el-select>
          <div class="error-msg">{{ fieldMsg }}</div>
        </el-col>
        <!-- 第二列：Operator -->
        <el-col :span="4">
          <el-select
            v-model="detail.Operator"
            @change="handleOperaChange($event, detail.FieldAPIName)"
            style="width: 100%"
            filterable
            :disabled="disabled"
          >
            <template v-for="item in operatorList">
              <el-option
                  v-if="detail.FieldAPIName !== 'FileName' || item.value === 'equals' || item.value === 'notEqual' "
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </template>
          </el-select>
          <div class="error-msg">{{ operatorMsg }}</div>
        </el-col>
        <!-- 第三列：值 -->
        <el-col :span="9">
          <!-- Operator选【为空】 -->
          <el-select
            v-if="detail.Operator === 'isNull'"
            v-model="detail.Value"
            style="width: 100%"
            filterable
            :disabled="disabled"
          >
            <el-option label="是" value="True"></el-option>
            <el-option label="否" value="False"></el-option>
          </el-select>
          <!-- Operator选【包括】、【不包括】，值为多选，选项取 field 的 PicklistOptions 字段 -->
          <div v-else-if="['includes', 'excludes'].includes(detail.Operator)">
            <el-select
              v-model="detail.Value"
              multiple
              placeholder="请选择或搜索"
              style="width: 100%"
              filterable
              :disabled="disabled"
            >
              <el-option
                v-for="item in picklist"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div v-else-if="field.DataType === 'Decimal'">
            <!-- <el-row :gutter="12">
            <el-col :span="11">
              <el-input
                type="number"
                placeholder="请输入内容"
                v-model="detail.Value[0]"
              ></el-input>
            </el-col>
            <el-col :span="2" style="line-height: 36px; text-align: center"
              >~</el-col
            >
            <el-col :span="11">
              <el-input
                type="number"
                placeholder="请输入内容"
                v-model="detail.Value[1]"
              ></el-input>
            </el-col>
          </el-row> -->
            <number-range
              ref="numberRange"
              :range="detail.Value"
              v-if="detail.Operator === 'between'"
              :disabled="disabled"
            ></number-range>
            <el-input
              v-else
              type="number"
              placeholder="请输入内容"
              v-model="detail.Value"
              :disabled="disabled"
            ></el-input>
          </div>
          <div
            v-else-if="
              field.DataType === 'Date' || field.DataType === 'DateTime'
            "
          >
            <el-date-picker
              v-if="detail.Operator === 'between'"
              v-model="detail.Value"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              :disabled="disabled"
            >
            </el-date-picker>
            <el-date-picker
              v-else
              v-model="detail.Value"
              type="date"
              placeholder="选择日期"
              style="width: 100%"
              value-format="yyyy-MM-dd"
              :disabled="disabled"
            >
            </el-date-picker>
          </div>
          <el-input
            v-else
            v-model="detail.Value"
            placeholder="请输入内容"
            :disabled="disabled"
          ></el-input>
          <div class="error-msg">{{ valueMsg }}</div>
        </el-col>
      </div>
      <el-col :span="2" style="line-height: 36px">
        <i v-if="!disabled" class="el-icon-close" @click="handleRemove"></i>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import numberRange from "@/components/number-range";
export default {
  components: {
    numberRange,
  },
  props: {
    index: {
      type: Number,
      default: 0,
    },
    detail: {
      type: Object,
      default: () => {return {}},
    },
    fields: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fieldsShow: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fieldMsg: "",
      operatorMsg: "",
      valueMsg: "",
    };
  },
  computed: {
    ...mapGetters({
      operatorMap: "common/operatorMap",
    }),
    field() {
      const name = this.detail.FieldAPIName;
      const field = this.fields.find((item) => item.APIName === name);
      return field || {};
    },
    operatorList() {
      if (this.field) {
        return this.operatorMap[this.field.DataType];
      } else {
        return [];
      }
    },
    picklist() {
      if (this.field) {
        return this.field.PicklistOptions || [];
      } else {
        return [];
      }
    },
  },
  methods: {
    handleRemove() {
      this.$emit("remove", this.detail);
    },
    handleFieldChange() {
      this.detail.Operator = "";
      this.detail.Value = "";
    },
    handleOperaChange(val, fieldAPIName) {
      if (fieldAPIName === 'FileName'){
        return
      }
      if (["includes", "excludes"].includes(val)) {
        this.detail.Value = [];
      } else if (val === "between") {
        this.detail.Value = ["", ""];
      } else {
        this.detail.Value = "";
      }
    },
    validate() {
      const { FieldAPIName, Operator, Value } = this.detail;
      let valid = true; // 是否校验通过
      if (!FieldAPIName) {
        this.fieldMsg = "请选择 field";
        valid = false;
      } else {
        this.fieldMsg = "";
      }

      if (!Operator) {
        this.operatorMsg = "请选择 operator";
        valid = false;
      } else {
        this.operatorMsg = "";
      }

      if (Array.isArray(Value)) {
        // value 是一个数组
        if (Value.length === 0) {
          // 数组为空，不通过
          this.valueMsg = "请输入 value";
          valid = false;
        } else if (Value.find((v) => !v) !== undefined) {
          // 数组中有空值，不通过
          this.valueMsg = "请输入完整的 value";
          valid = false;
        } else {
          this.valueMsg = "";
        }

        if (this.$refs.numberRange) {
          // 如果是 number-range，报错信息由 number-range 来定
          this.valueMsg = "";
          let msg = this.$refs.numberRange.getErrorMsg();
          if (msg !== "") {
            valid = false;
          }
        }
      } else if (!Value) {
        this.valueMsg = "请输入 value";
        valid = false;
      } else {
        this.valueMsg = "";
      }

      return valid;
    },
  },
};
</script>

<style lang="less" scoped>
.cond-item-customer {
  margin-bottom: 14px;
  .index-wrap {
  }
  .index {
    width: 22px;
    height: 22px;
    line-height: 22px;
    margin-top: 7px;
    font-size: 12px;
    background: #f6f7f8;
    border-radius: 50%;
    text-align: center;
  }
  .el-icon-close {
    font-weight: 500;
    color: #9a9a9e;
    cursor: pointer;
  }
  .error-msg {
    color: #f56c6c;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
  /deep/ .el-date-editor {
    .el-range-separator {
      padding: 0;
    }
  }
}
</style>
